import { Dialog, DialogContent } from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import { BigNumber, ethers } from "ethers";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import LoadingTable from "../../components/Base/LoadingTable";
import { appNetwork, BASE_CHAIN_ID } from "../../constants/network";
import useFetch from "../../hooks/useFetch";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import StakingHeader, {
  BENEFIT_ALL,
  BENEFIT_IDO_ONLY,
  BENEFIT_REWARD_ONLY,
  DURATION_FINISHED,
  DURATION_LIVE,
  POOL_TYPE_ALLOC,
  POOL_TYPE_LINEAR,
} from "./Header";
import useDetailListStakingPool from "./Pool/useDetailListStakingPool";
import ModalTransaction from "./ModalTransaction";
import AllocationPool from "./Pool/AllocationPool";
import LinearPool from "./Pool/LinearPool";
import useStyles from "./style";
import { pushMessage } from "../../store/actions/message";
import { getContractInstance, SmartContractMethod } from "../../services/web3";
import ERC20_ABI from "../../abi/Erc20.json";
import axios from "axios";
import * as BignumberJs from "bignumber.js";

const closeIcon = "/images/icons/close.svg";
const iconWarning = "/images/warning-red.svg";

const envLocal = localStorage?.getItem("env");
const env = envLocal ? JSON?.parse(envLocal) : {};

const DEFAULT_RPC_URL = env.REACT_APP_ETH_RPC_URL || "";

const provider = new ethers.providers.JsonRpcProvider(DEFAULT_RPC_URL);

const StakingPools = (props: any) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [blockNumber, setBlockNumber] = useState<number | undefined>(undefined);
  const connector = useTypedSelector((state) => state.connector).data;

  provider.on("block", (num: any) => {
    if (num && Number(num) !== blockNumber) {
      setBlockNumber(Number(num));
    }
  });

  const { appChainID, walletChainID } = useTypedSelector(
    (state) => state.appNetwork
  ).data;
  const { account: connectedAccount } = useWeb3React();
  const [durationType, setDurationType] = useState(DURATION_LIVE);
  const [poolType, setPoolType] = useState(POOL_TYPE_LINEAR);
  const [benefitType, setBenefitType] = useState(BENEFIT_ALL);
  const [stakedOnly, setStakedOnly] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [openModalTransactionSubmitting, setOpenModalTransactionSubmitting] =
    useState(false);
  const [transactionHashes, setTransactionHashes] = useState([]) as any;
  const { data, loading: loadingPoolList } = useFetch<any>(`/staking-pool`);
  const {
    stakingPoolInfo,
    linearPools,
    fetchDetailList,
    loading: loadingDetailList,
  } = useDetailListStakingPool(data?.list);
  const [currentPrice, setCurrentPrice] = useState<number>(0);
  const [reloadBalance, setReloadBalance] = useState<boolean>(false);
  const [tokenAllowance, setTokenAllowance] = useState<any>();

  const callTokenValue = async (contract: any, address: string) => {
    const allowance = await contract.methods
      .allowance(connectedAccount, address)
      .call();
    const allowanceReturn = new BignumberJs.BigNumber(allowance)
      .div(
        new BignumberJs.BigNumber(10).pow(stakingPoolInfo?.decimals as number)
      )
      .toString();
    setTokenAllowance(allowanceReturn);
  };

  useEffect(() => {
    const pools = Object.values(linearPools) as any;
    if (
      pools &&
      stakingPoolInfo?.address &&
      stakingPoolInfo?.decimals &&
      connectedAccount
    ) {
      const contract: any = getContractInstance(
        ERC20_ABI,
        stakingPoolInfo?.address,
        connector,
        appChainID,
        SmartContractMethod.Read
      );
      if (!contract) return;
      callTokenValue(contract, pools?.[0]?.pool_address);
    }
  }, [linearPools, reloadBalance, stakingPoolInfo]);

  const getTokenPrice = async () => {
    await axios
      .get(
        `https://api.cryptorank.io/v0/coins/crowdsales/values?coinKeys=${data?.sta?.coin_keys}`
      )
      .then((response) => {
        const dataPrice = response?.data?.[data?.sta?.coin_keys];
        const price = (dataPrice?.roi || 0) * (dataPrice?.icoIeoPrice || 0);
        setCurrentPrice(price);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (data?.sta?.coin_keys) {
      getTokenPrice();
    }
  }, [data?.sta?.coin_keys]);

  const stakingGlobalValue = useMemo(() => {
    const pools = Object.values(linearPools) as any;
    if (!pools || !pools?.length) return "";
    const totalSupplyPercen = stakingPoolInfo?.totalSupply
      ? (stakingPoolInfo?.poolBalance * 100) / stakingPoolInfo?.totalSupply
      : 0;
    return (
      <div className={styles.stakingStatistic}>
        <div>
          <p>{`Total ${stakingPoolInfo?.symbol} Staked (% of Total Supply)`}</p>
          <p>{`${stakingPoolInfo?.poolBalance?.toLocaleString("en-US", {
            maximumFractionDigits: 2,
          })} (${totalSupplyPercen?.toFixed(2)}%)`}</p>
        </div>
        <div>
          <p>Number of tokens Locked</p>
          <p>
            {data?.total?.toLocaleString("en-US", {
              maximumFractionDigits: 2,
            })}
          </p>
        </div>
        <div>
          <p>TVL</p>
          <p>
            ${(currentPrice * (data?.total || 0))?.toLocaleString("en-US", {
              maximumFractionDigits: 2,
            })}
          </p>
        </div>
      </div>
    );
  }, [data?.sta, linearPools, stakingPoolInfo, data?.total, currentPrice]);

  const [filteredAllocPools, setFilteredAllocPools] = useState([]) as any;
  const [filteredLinearPools, setFilteredLinearPools] = useState([]) as any;
  useEffect(() => {
    // let listAlloc = Object.values(allocPools);
    let listLinear = Object.values(linearPools);

    if (durationType === DURATION_FINISHED) {
      // listAlloc = listAlloc.filter((e: any) => e?.allocPoint === "0");
      listLinear = listLinear.filter(
        (e: any) =>
          Number(e?.endJoinTime) <= moment().unix() ||
          (BigNumber.from(e?.cap).gt(BigNumber.from("0")) &&
            BigNumber.from(e?.cap)
              .sub(BigNumber.from(e?.totalStaked))
              .eq(BigNumber.from("0")))
      );
    } else {
      // listAlloc = listAlloc.filter((e: any) => e?.allocPoint !== "0");
      listLinear = listLinear.filter(
        (e: any) =>
          Number(e?.endJoinTime) > moment().unix() &&
          (BigNumber.from(e?.cap).eq(BigNumber.from("0")) ||
            BigNumber.from(e?.cap)
              .sub(BigNumber.from(e?.totalStaked))
              .gt(BigNumber.from("0")))
      );
    }

    if (benefitType === BENEFIT_REWARD_ONLY) {
      // listAlloc = listAlloc.filter((e: any) => e?.point_rate === 0);
      listLinear = listLinear.filter((e: any) => e?.point_rate === 0);
    }

    if (benefitType === BENEFIT_IDO_ONLY) {
      // listAlloc = listAlloc.filter((e: any) => e?.point_rate > 0);
      listLinear = listLinear.filter((e: any) => e?.point_rate > 0);
    }

    if (searchString) {
      // listAlloc = listAlloc.filter(
      //   (e: any) =>
      //     (e?.title as string)
      //       .toLowerCase()
      //       .indexOf(searchString.toLowerCase()) !== -1
      // );
      listLinear = listLinear.filter(
        (e: any) =>
          (e?.title as string)
            .toLowerCase()
            .indexOf(searchString.toLowerCase()) !== -1
      );
    }

    if (stakedOnly) {
      // listAlloc = listAlloc.filter(
      //   (e: any) =>
      //     e?.stakingAmount !== "0" || e?.pendingWithdrawal?.amount !== "0"
      // );
      listLinear = listLinear.filter(
        (e: any) =>
          e?.stakingAmount !== "0" || e?.pendingWithdrawal?.amount !== "0"
      );
    }
    // setFilteredAllocPools(listAlloc);
    setFilteredLinearPools(listLinear);
  }, [
    linearPools,
    // allocPools,
    durationType,
    benefitType,
    stakedOnly,
    searchString,
  ]);
  const linearLivePools = useMemo(() => {
    let listLinear = Object.values(linearPools);
    return listLinear.filter(
      (e: any) =>
        Number(e?.endJoinTime) > moment().unix() &&
        (BigNumber.from(e?.cap).eq(BigNumber.from("0")) ||
          BigNumber.from(e?.cap)
            .sub(BigNumber.from(e?.totalStaked))
            .gt(BigNumber.from("0")))
    );
  }, [linearPools]);

  const reloadData = useCallback(async () => {
    fetchDetailList && fetchDetailList();
  }, [fetchDetailList]);

  useEffect(() => {
    if (
      appChainID !== BASE_CHAIN_ID ||
      appChainID !== walletChainID ||
      walletChainID !== BASE_CHAIN_ID
    ) {
      dispatch(
        pushMessage(
          `Your current chain is ${appNetwork?.[walletChainID]}. Please switch to the Base network to join these staking pools`
        )
      );
    } else {
      dispatch(pushMessage(""));
    }
    return () => {
      dispatch(pushMessage(""));
    };
  }, [appChainID, dispatch, walletChainID]);

  const renderLoading = () => {
    return (
      <div className={styles.loader}>
        <LoadingTable />
      </div>
    );
  };

  const renderAllocationPools = () => {
    return (
      <>
        <div className={styles.messageDuration}>
          <img src={iconWarning} style={{ marginRight: "12px" }} alt="" />
          UNI-V2 LP-RWA pool has stopped receiving LP-RWA staking. This policy
          is applied from October 4, 2021 until a new announcement.
        </div>
        <div className="pool-area">
          {filteredAllocPools.map((pool: any) => (
            <AllocationPool
              key={pool?.id}
              reload={reloadData}
              setTransactionHashes={setTransactionHashes}
              setOpenModalTransactionSubmitting={
                setOpenModalTransactionSubmitting
              }
              connectedAccount={connectedAccount}
              poolDetail={pool}
              blockNumber={blockNumber}
              poolAddress={pool?.pool_address}
              durationType={durationType}
            />
          ))}
        </div>
      </>
    );
  };

  const renderLinearPools = () => {
    return (
      <>
        <div className="pool-area">
          {filteredLinearPools.map((pool: any, index: number) => (
            <LinearPool
              key={index}
              reload={reloadData}
              setTransactionHashes={setTransactionHashes}
              setOpenModalTransactionSubmitting={
                setOpenModalTransactionSubmitting
              }
              tokenDetails={stakingPoolInfo}
              connectedAccount={connectedAccount}
              poolDetail={pool}
              poolAddress={pool?.pool_address}
              durationType={durationType}
              livePools={linearLivePools}
              balance={stakingPoolInfo?.balance}
              allowance={tokenAllowance}
              setReloadBalance={setReloadBalance}
            />
          ))}
        </div>
      </>
    );
  };

  const renderStakingPools = () => {
    if (poolType === POOL_TYPE_ALLOC && filteredAllocPools.length > 0)
      return renderAllocationPools();

    if (poolType === POOL_TYPE_LINEAR && filteredLinearPools.length > 0)
      return renderLinearPools();

    return (
      <div className="no-data">
        <img src="/images/nodata.png" alt="no data" />
        <p style={{ color: "black" }}>No data available.</p>
      </div>
    );
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className="content">
          <h1>Staking</h1>
          <div className={styles.stakingPoolContent}>{stakingGlobalValue}</div>

          <StakingHeader
            durationType={durationType}
            setDurationType={setDurationType}
            poolType={poolType}
            setPoolType={setPoolType}
            stakedOnly={stakedOnly}
            setStakedOnly={setStakedOnly}
            benefitType={benefitType}
            setBenefitType={setBenefitType}
            location={props.location}
            history={props.history}
            searchString={searchString}
            setSearchString={setSearchString}
          />

          {loadingDetailList || loadingPoolList
            ? renderLoading()
            : renderStakingPools()}
        </div>

        {openModalTransactionSubmitting && (
          <Dialog
            open={openModalTransactionSubmitting}
            keepMounted
            onClose={() => setOpenModalTransactionSubmitting(false)}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
            className={styles.submittingDialog}
          >
            <DialogContent className="content">
              <img
                src={closeIcon}
                alt=""
                onClick={() => setOpenModalTransactionSubmitting(false)}
              />
              <img
                src="/images/loading_submit.svg"
                className="loading animate-spin"
                width={48}
                height={48}
                alt="loading"
              />
              <span className="title">Transaction Submitting</span>
            </DialogContent>
          </Dialog>
        )}

        {transactionHashes.length > 0 && (
          <ModalTransaction
            transactionHashes={transactionHashes}
            setTransactionHashes={setTransactionHashes}
            open={transactionHashes.length > 0}
          />
        )}
      </div>
    </>
  );
};

export default withRouter(StakingPools);
