import React, { useEffect, useMemo, useState } from "react";
import useStyles from "./style";
import useFetch from "../../hooks/useFetch";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { getContractInstance, SmartContractMethod } from "../../services/web3";
import ERC20_ABI from "../../abi/Erc20.json";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { BASESCAN_URL, DEFAULT_CHAIN_ID } from "../../constants/network";
import { BigNumber, utils } from "ethers";

const tableHeaders = [
  "Contract Address",
  "Balance (RWA)",
  "Chain",
  "Type",
  "Name",
];

const Supply: React.FC<any> = (props: any) => {
  const styles = useStyles();
  const { data, loading: loadingPoolList } =
    useFetch<any>(`/get-supply-wallet`);
  const [listTracker, setListTracker] = useState<any>();
  const connector = useTypedSelector((state: any) => state.connector).data;
  const [trackerDetail, setTrackerDetail] = useState<any>();

  const getTrackerDetail = async (contract: any) => {
    const [totalBurn] = await Promise.all([
      contract.methods
        .balanceOf("0x000000000000000000000000000000000000dEaD")
        .call(),
    ]);
    const hardcodedTotalSupply = 1000000000; // 1 billion
    setTrackerDetail({
      totalSupply: hardcodedTotalSupply,
      totalBurn: +utils.formatEther(BigNumber.from(totalBurn)),
    });
  };

  const getListTracker = async (contract: any) => {
    try {
      const promise = data?.data?.map((value: any) => {
        return contract.methods.balanceOf(value?.wallet_address).call();
      });
      const result = await Promise.all(promise);
      const dataResult = data?.data?.map((value: any, index: number) => {
        return {
          ...value,
          balance: +utils.formatEther(BigNumber.from(result?.[index])) || 0,
        };
      });
      setListTracker(dataResult);
    } catch (e) {
      setListTracker(data?.data);
    }
  };

  const getTrackerData = async () => {
    // const tokenAddress = "0x66b43eF7f5316fA62CbEB2D9C2a66c57d8d74792";
    const tokenAddress = data?.data?.[0]?.token_address;
    const contract = getContractInstance(
      ERC20_ABI,
      tokenAddress,
      connector,
      DEFAULT_CHAIN_ID,
      SmartContractMethod.Read
    );
    if (!contract) return;
    getTrackerDetail(contract);
    getListTracker(contract);
  };

  useEffect(() => {
    if (data?.data?.length) {
      getTrackerData();
    }
  }, [data?.data]);

  const liveCirculatingSupply = useMemo(() => {
    if (!listTracker?.length) return 0;
    let total = trackerDetail?.totalBurn || 0;
    listTracker?.forEach((value: any) => {
      total += value?.balance;
    });
    const result = (trackerDetail?.totalSupply || 0) - (total || 0);
    return result < 0 ? 0 : result;
  }, [listTracker, trackerDetail?.totalBurn, trackerDetail?.totalSupply]);

  const valuesTotal = useMemo(() => {
    let totalLocked = 0;
    listTracker?.forEach((value: any) => {
      totalLocked += value?.balance;
    });
    const circulatingSupply =
      trackerDetail?.totalSupply - totalLocked - trackerDetail?.totalBurn;
    return {
      totalSupply: trackerDetail?.totalSupply || 0,
      totalBurn: trackerDetail?.totalBurn || 0,
      totalLocked: totalLocked || 0,
      circulatingSupply: (circulatingSupply < 0 ? 0 : circulatingSupply) || 0,
    };
  }, [listTracker, trackerDetail?.totalBurn, trackerDetail?.totalSupply]);

  useEffect(() => {
    if (!valuesTotal) return;
    const canvas: any = document.getElementById("myChart");
    if (!canvas) return;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const totalSupply =
      (valuesTotal?.circulatingSupply || 0) +
      (valuesTotal?.totalLocked || 0) +
      (valuesTotal?.totalBurn || 0);

    const dataChart = [
      { color: "#0055D6", value: valuesTotal?.circulatingSupply || 0 },
      { color: "White", value: 0.1 },
      { color: "#78A6FE", value: valuesTotal?.totalLocked || 0 },
      { color: "White", value: 0.1 },
      { color: "#7B7B7B", value: valuesTotal?.totalBurn || 0 },
      { color: "White", value: 0.001 * totalSupply },
    ];

    const totalValue = dataChart.reduce((acc, item) => acc + item.value, 0);
    let startAngle = 1.5 * Math.PI;

    dataChart.forEach((item) => {
      const sliceAngle = (item.value / totalValue) * 2 * Math.PI;

      ctx.beginPath();
      ctx.arc(150, 150, 150, startAngle, startAngle + sliceAngle);
      ctx.arc(150, 150, 80, startAngle + sliceAngle, startAngle);
      ctx.closePath();

      ctx.fillStyle = item.color;
      ctx.fill();

      startAngle += sliceAngle;
    });
  }, [
    valuesTotal,
    valuesTotal?.circulatingSupply,
    valuesTotal?.totalBurn,
    valuesTotal?.totalLocked,
  ]);

  return (
    <div className={styles.container}>
      <h2>$RWA Supply Tracker</h2>
      <div className={styles.chartHead}>
        <div className={styles.global}>
          <p>
            Total Supply:{" "}
            <span>
              {valuesTotal?.totalSupply?.toLocaleString("en-US", {
                maximumFractionDigits: 2,
              }) || 0}
            </span>
          </p>
          <p>
            Locked in Contracts:{" "}
            <span>
              {valuesTotal?.totalLocked?.toLocaleString("en-US", {
                maximumFractionDigits: 2,
              }) || 0}
            </span>
          </p>
          <p>
            Burnt $RWA:{" "}
            <span>
              {valuesTotal?.totalBurn?.toLocaleString("en-US", {
                maximumFractionDigits: 2,
              }) || 0}
            </span>
          </p>
          <p>
            Circulating Supply:{" "}
            <span>
              {valuesTotal?.circulatingSupply?.toLocaleString("en-US", {
                maximumFractionDigits: 2,
              }) || 0}
            </span>
          </p>
        </div>
        <div className={styles.chart}>
          <canvas id="myChart" width="300" height="300"></canvas>
          <div>
            <div className={styles.chartContent}>
              <div style={{ background: "#0055D6" }} />
              <p>Circulating Supply</p>
            </div>
            <div className={styles.chartContent}>
              <div style={{ background: "#78A6FE" }} />
              <p>Locked in Contracts</p>
            </div>
            <div className={styles.chartContent}>
              <div style={{ background: "#7B7B7B" }} />
              <p>Burnt $RWA</p>
            </div>
          </div>
        </div>
        <div />
      </div>
      <div style={{ height: 1, margin: "32px 0", background: "#7B7B7B" }} />
      <h2>$RWA Contracts</h2>
      <div>
        <TableContainer component={Paper}>
          {loadingPoolList ? (
            [...Array(10)].map((num, index) => (
              <div key={index}>
                <Skeleton width={"100%"} />
              </div>
            ))
          ) : (
            <Table
              className={styles.table}
              style={{ borderRadius: "8px", overflow: "hidden" }}
            >
              <TableHead style={{ background: "#0055D6" }}>
                <TableRow>
                  {tableHeaders.map((tableHeader: string, index: number) => (
                    <TableCell
                      key={index}
                      style={{
                        fontSize: "20px",
                        fontWeight: 600,
                        color: "#ffffff",
                      }}
                    >
                      {tableHeader}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {listTracker?.map((supply: any) => {
                  return (
                    <TableRow key={supply?.id}>
                      <TableCell
                        component="td"
                        scope="row"
                        className="wordBreak"
                        style={{ fontSize: "18px" }}
                      >
                        <a
                          href={`${BASESCAN_URL}/address/${supply?.wallet_address}`}
                          style={{ color: "#3C85BA", fontWeight: 500 }}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {supply?.wallet_address}
                        </a>
                      </TableCell>
                      <TableCell
                        component="td"
                        scope="row"
                        style={{ fontSize: "18px", fontWeight: 500 }}
                      >
                        <span>
                          {supply?.balance?.toLocaleString("en-US", {
                            maximumFractionDigits: 2,
                          }) || 0}
                        </span>
                      </TableCell>
                      <TableCell style={{ fontSize: "18px", fontWeight: 500 }}>
                        <span>{supply?.chain}</span>
                      </TableCell>
                      <TableCell style={{ fontSize: "18px", fontWeight: 500 }}>
                        <span>{supply?.type}</span>
                      </TableCell>
                      <TableCell style={{ fontSize: "18px", fontWeight: 500 }}>
                        <span>{supply?.name}</span>
                      </TableCell>
                    </TableRow>
                  );
                })}
                <TableRow style={{ background: "#78A6FE" }}>
                  <TableCell
                    component="td"
                    scope="row"
                    className="wordBreak"
                    style={{ fontSize: "18px" }}
                  >
                    <span style={{ fontWeight: 600 }}>Total $RWA Locked</span>
                  </TableCell>
                  <TableCell
                    component="td"
                    scope="row"
                    style={{ fontSize: "18px" }}
                  ></TableCell>
                  <TableCell style={{ fontSize: "18px" }}></TableCell>
                  <TableCell style={{ fontSize: "18px" }}></TableCell>
                  <TableCell style={{ fontSize: "18px" }}>
                    <span style={{ fontWeight: 600 }}>
                      {valuesTotal?.totalLocked?.toLocaleString("en-US", {
                        maximumFractionDigits: 2,
                      }) || 0}
                    </span>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </div>
    </div>
  );
};

export default Supply;
