import { useState, useEffect, useCallback } from "react";
import { BigNumber, ethers, utils } from "ethers";
import { useWeb3React } from "@web3-react/core";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { DEFAULT_CHAIN_ID, NODE_ENV } from "../../../constants/network";
import {
  getContractInstance,
  SmartContractMethod,
} from "../../../services/web3";

import STAKING_INFO_ABI from "../../../abi/StakingInfo.json";
import * as BignumberJs from "bignumber.js";

const useDetailListStakingPool = (poolsList: Array<any> | null | undefined) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [linearPools, setLinearPools] = useState({});
  const connector = useTypedSelector((state: any) => state.connector).data;
  const { account } = useWeb3React();
  const [stakingPoolInfo, setStakingPoolInfo] = useState<any>();

  const fetchDetailList = useCallback(async () => {
    try {
      if (!poolsList || !poolsList?.length) {
        return;
      }
      setLoading(true);
      let linears = {};
      const listId = poolsList?.map((pool) => BigNumber.from(pool.pool_id));
      const linearPoolAddress = poolsList?.[0]?.pool_address;
      if (!linearPoolAddress) return;
      const contract = getContractInstance(
        STAKING_INFO_ABI,
        NODE_ENV?.includes("pro")
          ? "0x8310454fE7A1CF0a08Bef333a034598aA4fcFb42"
          : "0x49381e2Adc0e2E5f9af55665a3f405ff9fD86196",
        connector,
        DEFAULT_CHAIN_ID,
        SmartContractMethod.Read
      );
      if (!contract) return;
      const userAddress =
        account || "0x0000000000000000000000000000000000000000";
      const userStakingData = await contract.methods
        .getUserStakingData(linearPoolAddress, userAddress, listId)
        .call();
      const poolList = userStakingData?.poolList || [];
      poolList?.forEach((pool: any, index: number) => {
        linears = {
          ...linears,
          [poolsList?.[index]?.pool_id]: {
            ...(poolsList?.[index] || {}),
            acceptedToken: userStakingData?.acceptedToken?.tokenAddress,
            cap: pool?.poolInfo?.cap,
            totalStaked: pool?.poolInfo?.totalStaked,
            minInvestment: pool?.poolInfo?.minInvestment,
            maxInvestment: pool?.poolInfo?.maxInvestment,
            APR: pool?.poolInfo?.APR,
            lockDuration: pool?.poolInfo?.lockDuration,
            delayDuration: pool?.poolInfo?.delayDuration,
            startJoinTime: pool?.poolInfo?.startJoinTime,
            endJoinTime: pool?.poolInfo?.endJoinTime,
            stakingAmount: pool?.linearStakingData?.balance || "0",
            stakingJoinedTime: pool?.linearStakingData?.joinTime || "0",
            pendingReward: pool?.linearPendingReward,
            pendingWithdrawal: {
              amount: pool?.linearPendingWithdrawal?.amount || "0",
              applicableAt: pool?.linearPendingWithdrawal?.applicableAt || "0",
            },
          },
        };
      });
      setLinearPools(linears);
      const decimals = Number(userStakingData?.acceptedToken?.decimals || "0");
      const balanceReturn = new BignumberJs.BigNumber(
        userStakingData?.acceptedToken?.balanceOf || 0
      )
        .div(new BignumberJs.BigNumber(10).pow(decimals as number))
        .toFixed(7);
      const info = {
        balance: balanceReturn,
        // decimals: 0,
        decimals: decimals,
        name: userStakingData?.acceptedToken?.name,
        symbol: userStakingData?.acceptedToken?.symbol || "RWA",
        address: userStakingData?.acceptedToken?.tokenAddress,
        totalSupply: +utils.formatEther(
          BigNumber.from(userStakingData?.acceptedToken?.totalSupply)
        ),
        poolBalance: +utils.formatEther(
          BigNumber.from(userStakingData?.poolBalance || 0)
        ),
      };
      setStakingPoolInfo(info);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  }, [poolsList, account]);

  useEffect(() => {
    fetchDetailList();
  }, [fetchDetailList]);

  return {
    loading,
    fetchDetailList,
    linearPools,
    stakingPoolInfo,
  };
};

export default useDetailListStakingPool;
