import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import BigNumber from "bignumber.js";
import { BigNumber as BigNumberEth, utils } from "ethers";
import { useEffect, useMemo, useRef, useState } from "react";
import useCommonStyle from "../../../styles/CommonStyle";
import usePoolStyles from "../Pool/style";
import useStyles from "./style";
import ProgressMovement from "../../../components/Base/Progress";

const closeIcon = "/images/icons/close.svg";
const warningIcon = "/images/warning-red.svg";
const ONE_DAY_IN_SECONDS = 86400;

const ModalStake = (props: any) => {
  const styles = useStyles();
  const poolStyles = usePoolStyles();
  const commonStyles = useCommonStyle();
  const progressRef = useRef<any>();

  const {
    open,
    onConfirm,
    onClose,
    amount,
    setAmount,
    pendingReward,
    delayDuration,
    tokenDetails,
    stakingAmount,
    tokenBalance,
  } = props;

  const [progress, setProgress] = useState("0");
  useEffect(() => {
    if (Number(utils.formatEther(stakingAmount || "0")) <= 0) {
      return;
    }
    setProgress(
      (
        (Number(amount) / Number(utils.formatEther(stakingAmount))) *
        100
      ).toFixed(0)
    );
  }, [amount, stakingAmount, setProgress]);

  const handleChangeUnstakeAmount = (event: any) => {
    let newAmount = event.target.value;
    let maxAmount = utils.formatEther(stakingAmount);
    setAmount(new BigNumber(newAmount).gt(maxAmount) ? maxAmount : newAmount);
  };

  const onSelectPercent = (percent: number) => {
    let newAmount = Number(utils.formatEther(stakingAmount)) * percent + "";
    setAmount(newAmount);
  };

  const progressStyle = useMemo(() => {
    if (!progressRef?.current) return 0;
    const maxWidth = progressRef?.current?.clientWidth;
    const maxItem = 52;
    if ((Number(progress) / 100) * maxWidth < maxItem / 2) {
      return 0;
    }
    if ((Number(progress) / 100) * maxWidth > maxWidth - maxItem / 2) {
      return maxWidth - maxItem;
    }
    return (Number(progress) / 100) * maxWidth - maxItem / 2;
  }, [progress]);

  return (
    <Dialog
      open={open}
      keepMounted
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      className={commonStyles.modal + " " + styles.modalStake}
    >
      <div className={poolStyles.modalContent}>
        <DialogTitle
          id="alert-dialog-slide-title"
          className={poolStyles.modalHeader}
        >
          <img src={closeIcon} alt="" onClick={onClose} className="btn-close" />
          <div className="title">Unstake</div>
        </DialogTitle>
        <DialogContent className={poolStyles.modalBody}>
          <div className={styles.warning}>
            <img src={warningIcon} alt="" />
            {Number(delayDuration) > 0 ? (
              <span>
                YOU ARE UNSTAKING RWA. Once you unstake, the unstaked RWA will
                not be calculated RWAs and you can only claim tokens after the
                withdrawal delay time.
                <br />
                If you ONLY want to CLAIM THE STAKING REWARD (without losing
                your RWAs), please close this popup {`>`} Click on the "Claim
                Reward" button in the "Earned" area.{" "}
              </span>
            ) : (
              <span>
                YOU ARE UNSTAKING RWA. Once you unstake, the unstaked RWA will
                not be calculated RWAs. <br /> If you ONLY want to CLAIM THE
                STAKING REWARD (without losing your RWAs), please close this
                popup {`>`} Click on the "Claim Reward" button in the "Earned"
                area.
              </span>
            )}
          </div>

          <div className="token-type">
            <div className="token-type-title">Token</div>
            <div className="token-detail">
              <div>{tokenDetails?.symbol}</div>
            </div>
          </div>
          <div className="token-type">
            <div className="token-type-title">Staking</div>
            <div className="token-detail">
              <div>
                {!BigNumberEth.from(stakingAmount || "0").eq(
                  BigNumberEth.from("0")
                ) && Number(utils.formatEther(stakingAmount)).toFixed(2)}
              </div>
            </div>
          </div>
          <div className="token-type">
            <div className="token-type-title">Current Profit</div>
            <div className="token-detail">
              <div>
                {!BigNumberEth.from(pendingReward || "0").eq(
                  BigNumberEth.from("0")
                ) && Number(utils.formatEther(pendingReward)).toFixed(2)}
              </div>
            </div>
          </div>
          {Number(delayDuration) > 0 && (
            <div className="token-type">
              <div className="token-type-title">Withdrawal delay time</div>
              <div className="token-detail">
                <div>
                  {" "}
                  {(Number(delayDuration) / ONE_DAY_IN_SECONDS).toFixed(0)} days
                </div>
              </div>
            </div>
          )}
          <div className="subtitle">
            Unstake Amount
            <div className="token-balance">
              (Balance: {new BigNumber(tokenBalance).toFixed(2)})
            </div>
          </div>
          <div className="input-group">
            <input
              value={amount}
              onChange={handleChangeUnstakeAmount}
              type="number"
              min="0"
            />
            <span>{tokenDetails?.symbol}</span>
          </div>

          <div className={poolStyles.groupButtonPercent}>
            <button
              onClick={() => onSelectPercent(0.25)}
              className={poolStyles.btnSelectPercent}
            >
              25%
            </button>
            <button
              onClick={() => onSelectPercent(0.5)}
              className={poolStyles.btnSelectPercent}
            >
              50%
            </button>
            <button
              onClick={() => onSelectPercent(0.75)}
              className={poolStyles.btnSelectPercent}
            >
              75%
            </button>
            <button
              onClick={() => onSelectPercent(1)}
              className={poolStyles.btnSelectPercent}
            >
              100%
            </button>
          </div>

          <div
            className={`${poolStyles.progressArea} ${poolStyles.modalProgress}`}
          >
            <ProgressMovement
              amount={amount}
              maxAmount={Number(utils.formatEther(stakingAmount))}
              setAmount={setAmount}
            />
          </div>

          {/* {Number(delayDuration) > 0 && (
            <ul
              style={{
                listStyleType: "disc",
                paddingLeft: "20px",
                margin: "20px 0",
              }}
            >
              <li>
                There is an Withdrawal delay time before you can{" "}
                <strong>Withdraw</strong> your Staked tokens. Following that
                Withdrawal delay time you will be able to withdraw.
              </li>
              <li style={{ marginTop: "10px" }}>
                Staking Rewards will stop being earned for the amount you
                unstake as soon as you click "<strong>Unstake</strong>" and
                initiate the Unstaking process
              </li>
            </ul>
          )} */}
        </DialogContent>
        <DialogActions className={poolStyles.modalFooterSingleBtn}>
          <button
            className={`${poolStyles.btn} ${poolStyles.btnModal} ${poolStyles.btnStakeModal}`}
            onClick={onConfirm}
            disabled={isNaN(amount) || Number(amount) <= 0}
          >
            Unstake
          </button>
        </DialogActions>
        {/* {transactionHashes[0].isApprove && <p className={styles.notice}>Please be patient and no need to approve again, you can check the transaction status on Etherscan.</p>} */}
      </div>
    </Dialog>
  );
};

export default ModalStake;
